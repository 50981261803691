import { React, useLayoutEffect, useState } from 'react';
import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import { ButtonGroup, Card, Container, Tab, Tabs } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';

import { MdOutlineDeliveryDining } from 'react-icons/md';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { useBreakpoints } from 'hooks/useBreakpoints';
import {
  CloseButton,
  EditButton,
  MoreButton
} from 'module/Common/Buttons/ViewPageButtons';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import SalesDeliveryView from './SalesDeliveryView';
import TransactionsData from 'module/Common/Timeline/TransactionsData';
import VoidTransactionTab from '../Common/VoidTransactionTab';
import CustomerDetails from 'components/customer-details/CustomerDetails';
import ViewPagePrintButton from '../Common/ViewPagePrintButton';
import AttachementsView from '../Common/AttachementsView';
import FileUpload from '../Common/FileUpload';
import SendEmail from '../Common/SendEmail';
import JournalTable from '../Common/Tables/JournalTable';
import PDFView from 'components/PDFViewerButton/PDFView';
import Badges from '../Common/ViewPageDetails/Badges';
import PersonName from '../Common/ViewPageDetails/PersonName';
import MainDetails from '../Common/ViewPageDetails/MainDetails';
import Reference from '../Common/ViewPageDetails/Reference';
import FooterDetails from '../Common/ViewPageDetails/FooterDetails';
import ApproveButton from 'components/approval-component/button/ApproveButton';
import RejectButton from 'components/approval-component/button/RejectButton';
import ActivityLog from 'components/activity-log/ActivityLog';
import BranchToBranch from 'components/inter-branch-transaction/components/BranchToBranch';
// import PurchaseFormSettingsOffcanvas from 'components/purchase-form-settings-offcanvas/PurchaseFormSettingsOffcanvas';

function SalesDeliveryTabView({
  salesDeliveryData,
  loading,
  infoPage,
  onHide,
  voidScreen,
  fetchData,
  offCanvas,
  setShowVoucherDetailArea,
  voidTransactionReport,
  getData,
  interBranch
}) {
  let navigateTo = useNavigate();
  const Translate = useAxisproTranslate();
  const { breakpoints } = useBreakpoints();
  const [queryParams] = useSearchParams();
  const [key, setKey] = useState(voidScreen ? 'voidForm' : 'overview');
  const [transactions, setTransactions] = useState([]);
  const [showCustomerDetailsArea, setCustomerDetailsArea] = useState(false);
  const [upload, setUpload] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const allQueryParams = Object.fromEntries([...queryParams]);
  const status = queryParams.get('status');
  const [tabApiCallCompleted, setTabApiCallCompleted] = useState({
    journal: false
  });
  const permission = {
    send_mail_sd: getAcodaxPermissionSlug('SD', 'send-mail'),
    update_sd: getAcodaxPermissionSlug('SD', 'update'),
    create_si: getAcodaxPermissionSlug('SI', 'create'),
    edit_sd: getAcodaxPermissionSlug('CSD', 'update_created'),
    show_cs: getAcodaxPermissionSlug('CS', 'show'),
    print_sd: getAcodaxPermissionSlug('SD', 'print'),
    void_sd: getAcodaxPermissionSlug('VOID', 'TRANSACTION')
  };
  const value = {
    value: salesDeliveryData.customer_id
  };

  const handleCustomerDetailsArea = () => {
    setCustomerDetailsArea(!showCustomerDetailsArea);
  };

  const sendEmail = () => {
    setShowEmailModal(true);
  };

  const handleCancel = () => {
    setShowEmailModal(false);
  };

  useLayoutEffect(() => {
    if (
      salesDeliveryData &&
      salesDeliveryData.connected_invoices &&
      salesDeliveryData.connected_order
    ) {
      let connected = [
        ...salesDeliveryData.connected_order,
        ...salesDeliveryData.connected_invoices
      ];
      let sorted = connected.sort((a, b) =>
        a.timestamp.localeCompare(b.timestamp)
      );
      setTransactions(sorted);
    }
  }, [salesDeliveryData]);

  return (
    <>
      <Card className="flex-fill view-page-content-wrapper">
        <Card.Header
          className={
            breakpoints.up('sm')
              ? 'd-flex m-0 align-items-center border-bottom'
              : 'border-bottom'
          }
        >
          <>
            <div className="d-flex m-0 align-items-center">
              <MdOutlineDeliveryDining size={30} className="me-2 text-dark" />
              <h5
                className="mb-0 d-flex text-uppercase view-page-header"
                style={{ fontSize: '25px' }}
              >
                {Translate(
                  interBranch ? 'Inter Branch Sales Delivery' : 'Sales Delivery'
                )}
              </h5>
            </div>
            {voidTransactionReport ? (
              <Badges label={'Voided'} className="ms-2 fs--1" bg={'danger'} />
            ) : null}
            {!interBranch && salesDeliveryData?.is_inter_branch_transaction ? (
              <BranchToBranch className={'ms-2'} badgeColor="info" size={15} />
            ) : null}
            <ButtonGroup className="ms-auto d-flex gap-2 flex-row align-items-center">
              {(status &&
                status !== 'approved-request' &&
                status !== 'active') ||
              voidScreen ||
              offCanvas ? (
                ''
              ) : (
                <MoreButton
                  sendEmail={sendEmail}
                  setUpload={setUpload}
                  firstConversionValidation={
                    (permission?.create_si &&
                      !interBranch &&
                      !salesDeliveryData?.is_inter_branch_transaction) ||
                    (permission?.create_si &&
                      interBranch &&
                      salesDeliveryData?.is_inter_branch_transaction &&
                      salesDeliveryData?.inter_branch_approval_trans_status !==
                        'rejected')
                  }
                  disableFirstConversion={
                    salesDeliveryData.is_fully_invoiced === true ||
                    (interBranch &&
                      salesDeliveryData?.inter_branch_approval_trans_status ===
                        'pending')
                  }
                  firstConversionTo={
                    interBranch
                      ? '/sales/inter-branch-transactions/sales-invoice/add/sd/' +
                        salesDeliveryData.id
                      : '/sales/sales-invoice/add/sd/' + salesDeliveryData.id
                  }
                  afterFirstConversion={
                    interBranch &&
                    salesDeliveryData?.inter_branch_approval_trans_status ===
                      'pending'
                      ? 'Waiting for PD approval'
                      : 'Invoiced!'
                  }
                  beforeFirstConversion={'Convert to sales invoice'}
                  emailPermission={permission?.send_mail_sd}
                  voidTransactionData={
                    permission?.void_sd
                      ? {
                          id: salesDeliveryData?.id,
                          trans_type: 'SD',
                          callBack: () => {
                            navigateTo(
                              interBranch
                                ? '/sales/inter-branch-transactions/sales-deliveries/info'
                                : '/sales/sales-deliveries/info'
                            );
                            typeof getData === 'function' && getData(1);
                          }
                        }
                      : undefined
                  }
                />
              )}
              {(status &&
                status !== 'approved-request' &&
                status !== 'active') ||
              voidScreen ||
              infoPage ||
              offCanvas ||
              salesDeliveryData?.is_auto_inter_branch_transaction ||
              salesDeliveryData?.inter_branch_approval_trans_status ===
                'approved' ? (
                ''
              ) : permission?.update_sd || permission?.edit_sd ? (
                <EditButton
                  to={
                    interBranch
                      ? `/sales/inter-branch-transactions/sales-deliveries/edit/${
                          salesDeliveryData.id
                        }?${createSearchParams({
                          ...allQueryParams
                        })}`
                      : `/sales/sales-deliveries/edit/${
                          salesDeliveryData.id
                        }?${createSearchParams({
                          ...allQueryParams
                        })}`
                  }
                />
              ) : (
                ''
              )}
              {(status &&
                status !== 'approved-request' &&
                status !== 'active') ||
                (!voidTransactionReport && permission?.print_sd && (
                  <ViewPagePrintButton
                    variant="danger"
                    className="text-white"
                    style={{ boxShadow: 'none' }}
                    url={`sales/delivery/print/${salesDeliveryData.id}`}
                    pageTitle="Sales delivery print view"
                  />
                ))}
              {/* <PurchaseFormSettingsOffcanvas type="SD" /> */}
              {infoPage || offCanvas ? (
                <CloseButton
                  setShowVoucherDetailArea={setShowVoucherDetailArea}
                  onHide={onHide}
                />
              ) : (
                ''
              )}
              {salesDeliveryData?.user_actions?.Approve && (
                <ApproveButton
                  name={salesDeliveryData?.user_actions?.Approve}
                  Id={salesDeliveryData?.approval_event_id}
                  data={salesDeliveryData}
                  getData={getData}
                  info={true}
                  approveRequestTab={interBranch ? false : true}
                />
              )}
              {salesDeliveryData?.user_actions?.Reject && (
                <RejectButton
                  name={salesDeliveryData?.user_actions?.Reject}
                  Id={salesDeliveryData?.approval_event_id}
                  data={salesDeliveryData}
                  getData={getData}
                  info={true}
                />
              )}
            </ButtonGroup>
          </>
        </Card.Header>
        <Card.Body className={loading && 'd-flex'}>
          <div className="d-flex flex-wrap">
            {salesDeliveryData?.customer_name ? (
              <PersonName
                label={interBranch ? 'Branch Name' : 'Customer Name'}
                value={salesDeliveryData?.customer_name}
                view
                permission={permission?.show_cs}
                setCustomerDetailsArea={setCustomerDetailsArea}
              />
            ) : null}
            <div className="w-100 mb-2 view-page-contents">
              {salesDeliveryData?.trans_date_formatted ? (
                <MainDetails
                  label={'Transaction Date'}
                  value={salesDeliveryData?.trans_date_formatted}
                />
              ) : null}
              {!salesDeliveryData?.is_auto && salesDeliveryData?.reference ? (
                <Reference
                  offCanvas={offCanvas}
                  voidTransactionReport={voidTransactionReport}
                  to={`/sales/sales-deliveries/info/${salesDeliveryData?.id}`}
                  value={salesDeliveryData?.reference}
                />
              ) : null}
            </div>
          </div>
          <Container className="p-0 view-page-tab-style-wrapper">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={k => setKey(k)}
              color="red"
            >
              {voidScreen ? (
                <Tab eventKey="voidForm" title={Translate('Voiding Form')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <VoidTransactionTab
                      transType={salesDeliveryData.trans_type}
                      transId={salesDeliveryData.id}
                    />
                  </SimpleBar>
                </Tab>
              ) : (
                ''
              )}
              <Tab eventKey="overview" title={Translate('Overview')}>
                <SimpleBar className="simplebar-view-page-style">
                  <SalesDeliveryView salesDeliveryData={salesDeliveryData} />
                </SimpleBar>
              </Tab>
              {status !== 'pending' ? (
                <Tab eventKey="journal" title={Translate('Journal')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <JournalTable
                      id={salesDeliveryData?.id}
                      transType={'SD'}
                      activeTabKey={key}
                      tabApiCallCompleted={tabApiCallCompleted?.journal}
                      setTabApiCallCompleted={setTabApiCallCompleted}
                    />
                  </SimpleBar>
                </Tab>
              ) : null}
              {transactions && transactions.length > 0 ? (
                <Tab eventKey="transaction" title={Translate('Transactions')}>
                  <SimpleBar className="simplebar-view-page-style-for-transaction">
                    <TransactionsData
                      transaction={transactions}
                      interBranch={interBranch}
                    />
                  </SimpleBar>
                </Tab>
              ) : null}
              {salesDeliveryData?.media?.length > 0 ? (
                <Tab eventKey="attachments" title={Translate('Attachments')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <AttachementsView
                      data={salesDeliveryData}
                      fetchData={fetchData}
                      setKey={setKey}
                    />
                  </SimpleBar>
                </Tab>
              ) : null}
              {salesDeliveryData?.id && status !== 'pending' ? (
                <Tab eventKey="pdfView" title={Translate('PDF View')}>
                  <SimpleBar className="simplebar-view-page-style pdf-tab-view-simplebar-style">
                    {key === 'pdfView' && (
                      <PDFView
                        viewType="tab"
                        url={`sales/delivery/print/${salesDeliveryData.id}`}
                      />
                    )}
                  </SimpleBar>
                </Tab>
              ) : null}
              {salesDeliveryData?.id && (
                <Tab eventKey="activityLog" title={Translate('Activity Log')}>
                  <SimpleBar className="simplebar-view-page-style pdf-tab-view-simplebar-style">
                    {key === 'activityLog' && (
                      <ActivityLog subjectID={salesDeliveryData?.id} />
                    )}
                  </SimpleBar>
                </Tab>
              )}
            </Tabs>
          </Container>
        </Card.Body>
        <FooterDetails
          created_at={salesDeliveryData?.created_at}
          created_by={salesDeliveryData?.created_user_full_name}
        />
      </Card>
      <CustomerDetails
        show={showCustomerDetailsArea}
        onHide={handleCustomerDetailsArea}
        value={value}
        showCustomerDetailsArea={showCustomerDetailsArea}
      />
      {salesDeliveryData.id && (
        <FileUpload
          show={upload}
          onHide={() => setUpload(false)}
          fetchData={fetchData}
          type={'SD'}
          id={salesDeliveryData.id}
          setKey={setKey}
        />
      )}
      <SendEmail
        url={'sales/sales-delivery-email/' + salesDeliveryData.id}
        showEmailModal={showEmailModal}
        handleCancel={handleCancel}
        data={salesDeliveryData.customer_email}
      />
    </>
  );
}

SalesDeliveryTabView.propTypes = {
  salesDeliveryData: PropTypes.any,
  loading: PropTypes.bool,
  infoPage: PropTypes.bool,
  onHide: PropTypes.func,
  voidScreen: PropTypes.bool,
  fetchData: PropTypes.func,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  voidTransactionReport: PropTypes.bool,
  interBranch: PropTypes.bool,
  getData: PropTypes.func
};

export default SalesDeliveryTabView;
