import React from 'react';

import { FaSpinner } from 'react-icons/fa';

const LoadingOverlay = () => {
  return (
    <div className={`loading-overlay is-active`}>
      <FaSpinner size={20} className="spinner text-light" />
    </div>
  );
};

export default LoadingOverlay;
