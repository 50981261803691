import React from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FaWhatsapp } from 'react-icons/fa';

import { showToast } from '../Toast/toast';
import useAxisproTranslate from 'hooks/useAxisproTranslate';

function ShareToWhatsAppButton({
  variant,
  iconSize,
  iconClassName,
  className,
  pdfName,
  tranNo,
  transType,
  setOpeningWhatsApp
}) {
  const Translate = useAxisproTranslate();

  const handleWhatsAppShare = () => {
    setOpeningWhatsApp(true);
    axios
      .get('get-print-document-url', {
        params: {
          trans_type: transType,
          trans_no: tranNo
        }
      })
      .then(response => {
        if (response?.data?.success) {
          let encodedMessage = '';
          const fileUrl = response?.data?.data?.file_url ?? '';
          if (fileUrl) {
            encodedMessage = encodeURIComponent(
              `Click here to download ${pdfName} print: ${fileUrl}`
            );
          }
          const whatsappUrl = `https://web.whatsapp.com/send?text=${encodedMessage}`;
          window.open(whatsappUrl, '_blank');
          setOpeningWhatsApp(false);
        } else {
          showToast('Something went wrong! Please contact admin', 'error');
          setOpeningWhatsApp(false);
        }
      })
      .catch(error => {
        if (error) {
          showToast('Something went wrong! Please contact admin', 'error');
          setOpeningWhatsApp(false);
        }
      });
  };
  return (
    <Button
      size="sm"
      variant={variant}
      className={className}
      title={Translate('Share PDF to whatsapp')}
      onClick={handleWhatsAppShare}
    >
      <FaWhatsapp className={iconClassName} size={iconSize} />
    </Button>
  );
}

ShareToWhatsAppButton.propTypes = {
  variant: PropTypes.string,
  iconSize: PropTypes.string,
  iconClassName: PropTypes.string,
  className: PropTypes.string,
  pdfName: PropTypes.string,
  tranNo: PropTypes.string,
  transType: PropTypes.string,
  setOpeningWhatsApp: PropTypes.func
};

export default ShareToWhatsAppButton;
