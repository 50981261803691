import useAxisproTranslate from 'hooks/useAxisproTranslate';
import React, { Fragment, useContext, useState } from 'react';
import { Alert, Button, Form, Offcanvas } from 'react-bootstrap';
import { FaCog } from 'react-icons/fa';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AppContext from 'context/Context';

export default function PurchaseFormSettingsOffcanvas({ type }) {
  const Translate = useAxisproTranslate();
  const { config, setConfig } = useContext(AppContext);
  const [show, setShow] = useState(false);
  const localStorageTargetKey = 'PURCHASE-FORM-SETTINGS';
  const contextResultTargetKey = type + '-FORM-SETTINGS';
  const data = config?.[contextResultTargetKey];

  const handleClose = event => {
    event?.preventDefault();
    setShow(!show);
  };

  const handleToggle = event => {
    const name = event.target?.name;
    if (!name) return;

    const nameDetails = name.split('-');
    const sectionIndex = nameDetails?.[1];
    const itemIndex = nameDetails?.[2];
    if (!sectionIndex || !itemIndex) return;

    const getUpdatedResult = sections => {
      const section = sections?.[sectionIndex];
      if (!section) return sections;
      const data = section?.data?.[itemIndex];
      if (!data) return sections;
      data.show = event.target.checked;

      const storedSettings = localStorage.getItem(localStorageTargetKey);

      localStorage.setItem(
        localStorageTargetKey,
        JSON.stringify({
          ...(storedSettings ? JSON.parse(storedSettings) : null),
          [type]: sections
        })
      );

      return sections;
    };

    setConfig(contextResultTargetKey, {
      ...data,
      sections: getUpdatedResult(data?.sections)
    });
  };

  return (
    <>
      <Button
        type="button"
        size="sm"
        variant="secondary"
        title={Translate('Settings')}
        onClick={handleClose}
        className="svg-spin-on-hover"
      >
        <FaCog />
      </Button>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header className="border-bottom" closeButton>
          <Offcanvas.Title
            as={'p'}
            className="fw-bold text-dark"
            style={{
              fontSize: '1rem'
            }}
          >
            {Translate(
              `${
                type !== 'DN' &&
                type !== 'SQ' &&
                type !== 'SO' &&
                type !== 'SD' &&
                type !== 'SI' &&
                type !== 'CN' &&
                type !== 'CT' &&
                type !== 'RCT' &&
                type !== 'FAP' &&
                type !== 'SE'
                  ? 'Purchase '
                  : ''
              }${data?.title} Settings`
            )}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <Alert variant="warning" className="rounded-0 m-0">
            {Translate(
              'Hi, you can hide or show the input fields related to this form by toggling the switch on this settings screen.'
            )}
            <br />
            <br />
            <b>{Translate('Note: ')}</b>
            {Translate(
              'For the mandatory fields, you should set a default value in the main settings before hiding them.'
            )}
          </Alert>
          <Form>
            <div className="p-3">
              {data?.sections?.map((section, sectionIndex) => (
                <Fragment key={'section-' + sectionIndex}>
                  <p
                    className={classNames('fw-bold text-uppercase text-dark', {
                      'mb-3': sectionIndex <= 0,
                      'mt-3': sectionIndex > 0
                    })}
                  >
                    {section?.title}
                  </p>
                  <div className="d-flex flex-column gap-2">
                    {section?.data?.map((item, itemIndex) => (
                      <Form.Group
                        key={`section-${sectionIndex}-item-${itemIndex}`}
                        className="d-flex flex-row align-items-center justify-content-between gap-2"
                        controlId={`purchase-form-settings-switch-input-section-${sectionIndex}-item-${itemIndex}`}
                      >
                        <Form.Label className="text-secondary">
                          {Translate(item?.title)}
                        </Form.Label>
                        <Form.Check
                          type="switch"
                          name={`input-${sectionIndex}-${itemIndex}`}
                          checked={item?.show}
                          onChange={handleToggle}
                        />
                      </Form.Group>
                    ))}
                  </div>
                </Fragment>
              ))}
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

PurchaseFormSettingsOffcanvas.propTypes = {
  type: PropTypes.oneOf([
    'PR',
    'PO',
    'PI',
    'PD',
    'DN',
    'SQ',
    'SO',
    'SD',
    'SI',
    'CN',
    'CT',
    'RCT',
    'FAP',
    'SE'
  ])
};
