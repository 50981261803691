import { React, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { showToast } from 'module/Common/Toast/toast';
import ItemMovementsLoading from 'module/Common/Loading/ItemMovementsLoading';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import ShippingAddressPopupForm from './ShippingAddressPopupForm';
import SimpleBar from 'simplebar-react';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import { BsFillTrashFill, BsPencilSquare } from 'react-icons/bs';
import { DeleteContext } from 'context/Context';
import { FaCity, FaMapMarkerAlt, FaPhone, FaUser } from 'react-icons/fa';
import { FcPlus } from 'react-icons/fc';
import { GrMapLocation } from 'react-icons/gr';
import ConfirmWarning from 'module/Common/Warnings/ConfirmWarning';

function CustomerShippingAddressTab({ itemData }) {
  const Translate = useAxisproTranslate();
  const [addressData, setAddressData] = useState([]);
  const { dispatch } = useContext(DeleteContext);
  const [pageLoading, setPageLoading] = useState(true);
  const [links, setLinks] = useState([]);
  const [showPopupForm, setShowPopupForm] = useState({
    show: false,
    edit: false,
    editItem: null
  });
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [onSave, setOnSave] = useState(false);
  const permission = {
    create_csa: getAcodaxPermissionSlug('CSA', 'create'),
    update_csa: getAcodaxPermissionSlug('CSA', 'update'),
    delete_csa: getAcodaxPermissionSlug('CSA', 'delete')
  };

  const fetchData = async () => {
    setPageLoading(true);
    await axios
      .get(`sales/customer/${itemData?.id}/shipping-addresses`)
      .then(res => {
        if (res.data.success) {
          setPageLoading(false);
          const data = res.data.data.data;
          setAddressData(data);
          setLinks(res.data.data);
        }
      })
      .catch(error => {
        setPageLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const loadNextPage = async next_page_url => {
    if (next_page_url) {
      await axios
        .get(next_page_url)
        .then(res => {
          setAddressData([...addressData, ...res.data.data.data]);
          setLinks(res.data.data);
        })
        .catch(error => {
          console.log(error);
          showToast(
            Translate('Something went wrong please contact admin'),
            'error'
          );
        });
    }
  };

  const handleDelete = data => {
    dispatch({
      type: 'CONFIG',
      payload: {
        target: data.id,
        url: `sales/customer/${itemData?.id}/shipping-addresses/${data?.id}`,
        title: Translate('Delete Customer Shipping Address'),
        message: Translate(
          'Are you sure? You want to delete this Customer Shipping Address!'
        ),
        onSuccess: () => {
          const dataWithoutDeletedItem = addressData.filter(
            address => address.id !== data.id
          );
          setAddressData(dataWithoutDeletedItem);
          // reset deleteContext
          dispatch({
            type: 'RESET'
          });
        }
      }
    });
  };

  const handleEdit = data => {
    setShowPopupForm({
      show: true,
      editItem: data
    });
  };

  const handleClosePopupForm = () => {
    setShowPopupForm({
      show: false,
      editItem: null
    });
  };

  const handleSetDefaultAddress = async data => {
    setOnSave(true);
    await axios
      .put(
        `sales/customer/${itemData?.id}/shipping-addresses/${data?.id}/set-default`
      )
      .then(res => {
        if (res.data.success) {
          showToast(res.data.message, 'success');
          fetchData();
          setOnSave(false);
          handleCloseConfirm();
        }
      })
      .catch(error => {
        console.log(error);
        showToast(
          Translate('Something went wrong please contact admin'),
          'error'
        );
        setOnSave(false);
      });
  };

  const handleShowConfirm = data => {
    if (permission?.update_csa && !data.is_default) {
      setShowConfirm(true);
      setSelectedAddress(data);
    }
  };

  const handleCloseConfirm = () => {
    setShowConfirm(false);
    setSelectedAddress(null);
  };

  return (
    <>
      <Container className="ps-1 pe-1">
        {permission?.create_csa && (
          <FcPlus
            size={20}
            style={{
              top: -25,
              right: -12
            }}
            title="Add Shipping Address"
            className="cursor-pointer me-3 position-absolute"
            onClick={() => setShowPopupForm({ show: true, editItem: null })}
          />
        )}
        {!pageLoading ? (
          <SimpleBar className="simplebar-view-page-tab-height-for-rct">
            {addressData.length > 0 ? (
              <div className="mt-2">
                <Row className="m-0">
                  {addressData.map((address, index) => (
                    <Col
                      key={index}
                      xs={12}
                      md={6}
                      className="mt-0 d-flex cursor-pointer px-2"
                      onClick={() => handleShowConfirm(address)}
                    >
                      <div
                        style={{
                          backgroundColor: address?.is_default ? '#f5fcff' : '',
                          borderColor: address?.is_default ? '#08C2FF' : '#ccc',
                          borderWidth: address?.is_default ? '2px' : '1px',
                          borderStyle: 'solid'
                        }}
                        className={`p-3 pe-2 mb-3 d-flex align-items-start justify-content-between flex-fill ${
                          !address?.is_default &&
                          ' customer-shipping-address-card-view'
                        }`}
                      >
                        <div className="d-flex flex-column w-100">
                          <div className="d-flex align-items-center mb-2">
                            <GrMapLocation
                              size={18}
                              style={{ minWidth: 'fit-content' }}
                              className="me-2 text-dark"
                            />
                            <span
                              className="fw-bold text-dark text-capitalize"
                              style={{ fontSize: '16px' }}
                            >
                              {address?.address_type}
                            </span>
                            {address?.is_default && (
                              <div
                                style={{
                                  backgroundColor: '#08C2FF',
                                  borderRadius: '8px',
                                  fontSize: '10px'
                                }}
                                className="fw-bold text-white ms-2 px-3 py-0"
                              >
                                {Translate('Default')}
                              </div>
                            )}
                          </div>
                          <div className="d-flex align-items-center mb-2">
                            <FaUser
                              size={14}
                              style={{ minWidth: 'fit-content' }}
                              className="me-2 text-dark"
                            />
                            <span className="fw-bold text-capitalize">
                              {address?.first_name} {address?.last_name}
                            </span>
                          </div>
                          <div className="d-flex align-items-center mb-2">
                            <FaPhone
                              size={14}
                              style={{ minWidth: 'fit-content' }}
                              className="me-2 text-dark"
                            />
                            <span className="fw-bold">
                              {address?.phone_number || '--'}
                            </span>
                          </div>
                          <div className="d-flex align-items-start mb-2">
                            <FaMapMarkerAlt
                              size={15}
                              style={{ minWidth: 'fit-content' }}
                              className="me-2 mt-1 text-dark"
                            />
                            <div className="flex-grow-1">
                              <p className="mb-1">
                                <span className="text-muted">
                                  {Translate('Address Line 1')}:
                                </span>
                                <span className="ms-1 fw-bold">
                                  {address?.address1}
                                </span>
                              </p>
                              {address?.address2 && (
                                <p className="mb-0">
                                  <span className="text-muted">
                                    {Translate('Address Line 2')}:
                                  </span>
                                  <span className="ms-1 fw-bold">
                                    {address?.address2}
                                  </span>
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <FaCity
                              size={16}
                              style={{ minWidth: 'fit-content' }}
                              className="me-2 text-dark"
                            />
                            <span className="fw-bold text-capitalize">
                              {address?.country}, {address?.city},{' '}
                              {address?.state} {address?.zip_code}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end">
                          {permission?.update_csa && (
                            <Button
                              variant="transparent"
                              className=" p-0 m-0 ps-1 pe-1 text-info shadow-none cursor-pointer"
                              title={Translate('Edit')}
                              onClick={e => {
                                e.stopPropagation();
                                handleEdit(address);
                              }}
                            >
                              <BsPencilSquare size={14} className="text-info" />
                            </Button>
                          )}
                          {permission?.delete_csa && (
                            <Button
                              variant="transparent"
                              className=" p-0 m-0 ps-2 pe-1 text-danger"
                              style={{ boxShadow: 'none' }}
                              onClick={e => {
                                e.stopPropagation();
                                handleDelete(address);
                              }}
                              title={Translate('Delete')}
                            >
                              <BsFillTrashFill size={14} />
                            </Button>
                          )}
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            ) : (
              <div
                style={{ backgroundColor: '#e307272e' }}
                className=" p-2 d-flex align-items-center justify-content-center mt-3"
              >
                <span className="text-center   fs--1 ">
                  No address found 🙂{' '}
                </span>
              </div>
            )}
            {links &&
              links.meta &&
              links.meta.current_page !== links.meta.last_page && (
                <ItemMovementsLoading
                  loadNextPage={loadNextPage}
                  links={links}
                  row={3}
                  column={7}
                />
              )}
          </SimpleBar>
        ) : (
          <Container
            style={{ height: '75vh' }}
            className="d-flex align-items-center justify-content-center"
          >
            <LoadingScreen message={'Looking for details'} />
          </Container>
        )}
      </Container>
      <ConfirmWarning
        show={showConfirm}
        onHide={handleCloseConfirm}
        handleSubmit={() => handleSetDefaultAddress(selectedAddress)}
        modalTitle="Change Default Address"
        content={'Are you sure you want to set this as default address?'}
        onSave={onSave}
      />
      <ShippingAddressPopupForm
        customerData={itemData}
        show={showPopupForm?.show}
        onHide={handleClosePopupForm}
        fetchData={fetchData}
        editItem={showPopupForm?.editItem}
      />
    </>
  );
}
CustomerShippingAddressTab.propTypes = {
  itemData: PropTypes.any
};

export default CustomerShippingAddressTab;
