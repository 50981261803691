import { React, useLayoutEffect, useState } from 'react';
import {
  useNavigate,
  useOutletContext,
  useSearchParams
} from 'react-router-dom';
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Container,
  Tab,
  Tabs
} from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';

import { FaExternalLinkAlt } from 'react-icons/fa';
import { AiOutlineFileText } from 'react-icons/ai';

import { useBreakpoints } from 'hooks/useBreakpoints';
import { CloseButton, MoreButton } from 'module/Common/Buttons/ViewPageButtons';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import PurchaseDeliveryView from './PurchaseDeliveryView';
import VoidTransactionTab from '../Common/VoidTransactionTab';
import SupplierDetails from 'components/supplier-details/SupplierDetails';
import TransactionsData from 'module/Common/Timeline/TransactionsData';
import ViewPagePrintButton from '../Common/ViewPagePrintButton';
import AttachementsView from '../Common/AttachementsView';
import FileUpload from '../Common/FileUpload';
import SendEmail from '../Common/SendEmail';
import JournalTable from '../Common/Tables/JournalTable';
import useAxisproPermission from 'hooks/useAxisproPermission';
import PDFView from 'components/PDFViewerButton/PDFView';
import TabViewGeneralDetailsSectionCover from 'components/tab-view-general-details-section-cover/TabViewGeneralDetailsSectionCover';
import ShowReference from 'components/show-reference/ShowReference';
import ApproveButton from 'components/approval-component/button/ApproveButton';
import RejectButton from 'components/approval-component/button/RejectButton';
import BranchToBranch from 'components/inter-branch-transaction/components/BranchToBranch';
// import PurchaseFormSettingsOffcanvas from 'components/purchase-form-settings-offcanvas/PurchaseFormSettingsOffcanvas';

function PurchaseDeliveryTabView({
  purchaseDeliveryData,
  loading,
  // infoPage,
  voidScreen,
  fetchData,
  offCanvas,
  setShowVoucherDetailArea,
  onHide,
  voidTransactionReport,
  getData,
  interBranch
}) {
  const { breakpoints } = useBreakpoints();
  const axisProPermission = useAxisproPermission();
  const navigateTo = useNavigate();
  let [permissions] = useOutletContext() ?? [];
  if (!permissions) {
    permissions = {
      sendMail: axisProPermission('send-mail-pd'),
      print: axisProPermission('print-pd'),
      create: axisProPermission('create-pd'),
      edit: axisProPermission('update-pd'),
      voidTransaction: axisProPermission('transaction-void'),
      createPurchaseInvoice: axisProPermission('create-pi')
    };
  }
  const [queryParams] = useSearchParams();
  const status = queryParams.get('status');
  const Translate = useAxisproTranslate();
  const [key, setKey] = useState(voidScreen ? 'voidForm' : 'overview');
  const [transactions, setTransactions] = useState([]);
  const [showSupplierDetailsArea, setSupplierDetailsArea] = useState(false);
  const [upload, setUpload] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [tabApiCallCompleted, setTabApiCallCompleted] = useState({
    journal: false
  });
  const isFullyInvoiced =
    purchaseDeliveryData?.is_fully_invoiced ||
    purchaseDeliveryData?.is_connected_order_fully_invoiced;

  const value = {
    value: purchaseDeliveryData.supplier_id
  };

  const handleSupplierDetailsArea = () => {
    setSupplierDetailsArea(!showSupplierDetailsArea);
  };

  const sendEmail = () => {
    setShowEmailModal(true);
  };

  const handleCancel = () => {
    setShowEmailModal(false);
  };

  useLayoutEffect(() => {
    if (
      purchaseDeliveryData &&
      purchaseDeliveryData.connected_invoices &&
      purchaseDeliveryData.connected_order
    ) {
      let connected = [
        ...purchaseDeliveryData.connected_order,
        ...purchaseDeliveryData.connected_invoices
      ];
      let sorted = connected.sort((a, b) =>
        a.timestamp.localeCompare(b.timestamp)
      );
      setTransactions(sorted);
    }
  }, [purchaseDeliveryData]);

  return (
    <>
      <Card className="flex-fill view-page-content-wrapper">
        <Card.Header
          className={
            breakpoints.up('sm')
              ? 'd-flex m-0 align-items-center border-bottom'
              : 'border-bottom'
          }
        >
          <>
            <AiOutlineFileText size={28} className="me-2 text-dark" />
            <h5 className="mb-0 d-flex text-uppercase view-page-header">
              {Translate(
                interBranch
                  ? 'Inter Branch Purchase delivery'
                  : 'Purchase delivery'
              )}
            </h5>
            {voidTransactionReport && (
              <Badge bg="danger" className="ms-2 fs--1">
                {Translate('Voided')}
              </Badge>
            )}
            {!interBranch &&
            purchaseDeliveryData?.is_inter_branch_transaction ? (
              <BranchToBranch className={'ms-2'} badgeColor="info" size={15} />
            ) : null}
            <ButtonGroup className="ms-auto d-flex gap-2 flex-row  align-items-center">
              {(status &&
                status !== 'approved-request' &&
                status !== 'active') ||
              voidScreen ||
              offCanvas ? (
                ''
              ) : (
                <>
                  <MoreButton
                    sendEmail={sendEmail}
                    setUpload={setUpload}
                    firstConversionValidation={
                      (permissions?.createPurchaseInvoice &&
                        !interBranch &&
                        !purchaseDeliveryData?.is_inter_branch_transaction) ||
                      (permissions?.createPurchaseInvoice &&
                        interBranch &&
                        purchaseDeliveryData?.is_inter_branch_transaction &&
                        purchaseDeliveryData?.inter_branch_approval_trans_status !==
                          'rejected')
                    }
                    disableFirstConversion={
                      isFullyInvoiced ||
                      (interBranch &&
                        purchaseDeliveryData?.inter_branch_approval_trans_status ===
                          'pending')
                    }
                    firstConversionTo={
                      `/purchase/${
                        interBranch ? 'inter-branch-transactions/' : ''
                      }purchase-invoice/add?delivery_id=` +
                      purchaseDeliveryData.id
                    }
                    afterFirstConversion={
                      purchaseDeliveryData?.is_fully_invoiced
                        ? 'Fully Invoiced!'
                        : interBranch &&
                          purchaseDeliveryData?.inter_branch_approval_trans_status ===
                            'pending'
                        ? 'Waiting for SD approval'
                        : 'Connected Order Fully Invoiced'
                    }
                    beforeFirstConversion={'Convert to purchase invoice'}
                    emailPermission={permissions?.sendMail}
                    clonePath={
                      permissions?.create &&
                      !interBranch &&
                      !purchaseDeliveryData?.is_inter_branch_transaction
                        ? '/purchase/purchase-delivery/add?clone_item=' +
                          purchaseDeliveryData?.id
                        : undefined
                    }
                    voidTransactionData={
                      permissions?.voidTransaction
                        ? {
                            id: purchaseDeliveryData?.id,
                            trans_type: 'PD',
                            callBack: () => {
                              navigateTo(
                                `/purchase/${
                                  interBranch
                                    ? 'inter-branch-transactions/'
                                    : ''
                                }purchase-delivery?layout=column`
                              );
                              typeof getData === 'function' && getData(1);
                            }
                          }
                        : undefined
                    }
                  />

                  {/* {!infoPage && permissions?.edit && (
                    <EditButton
                      to={`/purchase/purchase-delivery/edit/${purchaseDeliveryData.id}`}
                    />
                  )} */}
                </>
              )}
              {(status &&
                status !== 'approved-request' &&
                status !== 'active') ||
                (permissions?.print && !voidTransactionReport && (
                  <ViewPagePrintButton
                    variant="danger"
                    className="text-white"
                    style={{ boxShadow: 'none' }}
                    url={`purchase/purchase-deliveries/print/${purchaseDeliveryData.id}`}
                    pageTitle="Purchase Delivery print view"
                  />
                ))}
              {/* <PurchaseFormSettingsOffcanvas type="PD" /> */}
              {offCanvas || onHide ? (
                <CloseButton
                  setShowVoucherDetailArea={setShowVoucherDetailArea}
                  onHide={onHide}
                />
              ) : (
                ''
              )}
              {purchaseDeliveryData?.user_actions?.Approve && (
                <ApproveButton
                  name={purchaseDeliveryData?.user_actions?.Approve}
                  Id={purchaseDeliveryData?.approval_event_id}
                  data={purchaseDeliveryData}
                  getData={getData}
                  info={true}
                  approveRequestTab={interBranch ? false : true}
                />
              )}

              {purchaseDeliveryData?.user_actions?.Reject && (
                <RejectButton
                  name={purchaseDeliveryData?.user_actions?.Reject}
                  Id={purchaseDeliveryData?.approval_event_id}
                  data={purchaseDeliveryData}
                  getData={getData}
                  info={true}
                />
              )}
            </ButtonGroup>
          </>
        </Card.Header>
        <Card.Body className={loading && 'd-flex'}>
          <TabViewGeneralDetailsSectionCover mainTitle="General Details">
            <TabViewGeneralDetailsSectionCover.Section position="start">
              <TabViewGeneralDetailsSectionCover.Item
                title={interBranch ? 'Branch Name' : 'Supplier Name'}
                value={purchaseDeliveryData?.supplier_name}
                EndElement={
                  <Button
                    variant="transparent"
                    className="p-0 mb-2 text-primary outline-none"
                    onClick={() => setSupplierDetailsArea(true)}
                    size="sm"
                  >
                    <FaExternalLinkAlt size={10} />
                  </Button>
                }
              />

              <TabViewGeneralDetailsSectionCover.Item
                title="Transaction Date"
                value={purchaseDeliveryData?.trans_date_formatted}
              />
            </TabViewGeneralDetailsSectionCover.Section>

            <TabViewGeneralDetailsSectionCover.Section position="end">
              <TabViewGeneralDetailsSectionCover.Item
                title="Reference"
                contentSectionClassName="mb-2"
              >
                <ShowReference
                  data={purchaseDeliveryData}
                  to={`/purchase/${
                    interBranch ? 'inter-branch-transactions/' : ''
                  }purchase-delivery?entry=${
                    purchaseDeliveryData?.id
                  }&layout=column`}
                  offCanvas={offCanvas}
                  readOnly={voidTransactionReport}
                />
              </TabViewGeneralDetailsSectionCover.Item>

              <TabViewGeneralDetailsSectionCover.Item
                title="Tax Included"
                value={
                  [true, 1, '1'].includes(purchaseDeliveryData?.tax_included)
                    ? 'Included'
                    : 'Excluded'
                }
              />

              <TabViewGeneralDetailsSectionCover.Item
                title="Payment Term"
                value={purchaseDeliveryData?.payment_term}
              />
            </TabViewGeneralDetailsSectionCover.Section>

            <TabViewGeneralDetailsSectionCover.CustomColumnResultSection
              customColumnItems={purchaseDeliveryData?.custom_field_values}
            />
          </TabViewGeneralDetailsSectionCover>

          <Container className="p-0 view-page-tab-style-wrapper">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={k => setKey(k)}
              color="red"
            >
              {voidScreen ? (
                <Tab eventKey="voidForm" title={Translate('Voiding Form')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <VoidTransactionTab
                      transType={purchaseDeliveryData.trans_type}
                      transId={purchaseDeliveryData.id}
                    />
                  </SimpleBar>
                </Tab>
              ) : (
                ''
              )}
              <Tab eventKey="overview" title={Translate('Overview')}>
                <SimpleBar className="simplebar-view-page-style">
                  <PurchaseDeliveryView
                    purchaseDeliveryData={purchaseDeliveryData}
                  />
                </SimpleBar>
              </Tab>
              {purchaseDeliveryData?.inter_branch_approval_trans_status ===
                'pending' || status === 'pending' ? null : (
                <Tab eventKey="journal" title={Translate('Journal')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <JournalTable
                      id={purchaseDeliveryData?.id}
                      transType={'PD'}
                      activeTabKey={key}
                      tabApiCallCompleted={tabApiCallCompleted?.journal}
                      setTabApiCallCompleted={setTabApiCallCompleted}
                    />
                  </SimpleBar>
                </Tab>
              )}
              {transactions && transactions.length > 0 && (
                <Tab eventKey="transaction" title={Translate('Transactions')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <TransactionsData
                      transaction={transactions}
                      interBranch={interBranch}
                    />
                  </SimpleBar>
                </Tab>
              )}
              {purchaseDeliveryData.media &&
              purchaseDeliveryData.media.length > 0 ? (
                <Tab eventKey="attachments" title={Translate('Attachments')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <AttachementsView
                      data={purchaseDeliveryData}
                      fetchData={fetchData}
                      setKey={setKey}
                    />
                  </SimpleBar>
                </Tab>
              ) : (
                ''
              )}

              {purchaseDeliveryData?.id && status !== 'pending' ? (
                <Tab eventKey="pdfView" title={Translate('PDF View')}>
                  <SimpleBar className="simplebar-view-page-style pdf-tab-view-simplebar-style">
                    <PDFView
                      viewType="tab"
                      url={`purchase/purchase-deliveries/print/${purchaseDeliveryData.id}`}
                    />
                  </SimpleBar>
                </Tab>
              ) : null}
            </Tabs>
          </Container>
        </Card.Body>
        <Card.Footer className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-3">
            <h6 className="m-0 fw-bold">{Translate('Created By')}:&nbsp;</h6>
            <h6 className="m-0">
              {purchaseDeliveryData &&
              purchaseDeliveryData.created_user_full_name
                ? purchaseDeliveryData.created_user_full_name
                : 'null'}
            </h6>
          </div>
          <div className="d-flex align-items-center gap-3">
            <h6 className="m-0 fw-bold">{Translate('Created At')}:&nbsp;</h6>
            <h6 className="m-0">
              {purchaseDeliveryData && purchaseDeliveryData.created_at
                ? purchaseDeliveryData.created_at
                : 'null'}
            </h6>
          </div>
        </Card.Footer>
      </Card>
      <SupplierDetails
        show={showSupplierDetailsArea}
        onHide={handleSupplierDetailsArea}
        value={value}
        showSupplierDetailsArea={showSupplierDetailsArea}
      />
      <FileUpload
        show={upload}
        onHide={() => setUpload(false)}
        fetchData={fetchData}
        type={'PD'}
        id={purchaseDeliveryData.id}
        setKey={setKey}
      />
      <SendEmail
        url={
          'purchase/purchase-deliveries/send-mail/' + purchaseDeliveryData.id
        }
        showEmailModal={showEmailModal}
        handleCancel={handleCancel}
        data={purchaseDeliveryData.supplier_email}
      />
    </>
  );
}

PurchaseDeliveryTabView.propTypes = {
  purchaseDeliveryData: PropTypes.any,
  loading: PropTypes.bool,
  voidScreen: PropTypes.bool,
  fetchData: PropTypes.func,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  onHide: PropTypes.bool,
  voidTransactionReport: PropTypes.bool,
  infoPage: PropTypes.bool,
  getData: PropTypes.func,
  interBranch: PropTypes.bool
};

export default PurchaseDeliveryTabView;
