import { React, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { StoreContext, AuthWizardContext } from 'context/Context';
import eventEmitter from './eventEmiter';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';

const InitStore = ({ children }) => {
  const [loadComplete, setLoadComplete] = useState(false);
  let user = useContext(AuthWizardContext);
  const { addIitemToStore } = useContext(StoreContext);
  const showMultiBranch = getAcodaxPermissionSlug('BRANCH', 'switch');
  const getCurrentDate = () => {
    const current = new Date();
    const currentDate = `${current.getFullYear()}-${(
      '0' +
      (current.getMonth() + 1)
    ).slice(-2)}-${('0' + current.getDate()).slice(-2)}`;
    addIitemToStore('currentDate', currentDate);
  };

  const getLatestVersion = async () => {
    await axios
      .get('app-versions/latest/react')
      .then(res => {
        if (res?.data?.success) {
          if (res?.data?.data) {
            addIitemToStore('latestVersion', res?.data?.data);
          }
        }
      })
      .catch(error => {
        if (error) {
          console.log(error);
        }
      });
  };

  const globalConfiguration = () => {
    let finance_settings = user?.user?.branch?.finance_settings;
    let sales_settings = user?.user?.branch?.sales_settings;
    let system_settings = user?.user?.branch?.system_settings;
    let purchase_settings = user?.user?.branch?.purchase_settings;
    addIitemToStore('globalConfiguration', {
      tax_label: finance_settings?.tax_label ?? 'TRN',
      round_off_max_limit: finance_settings?.round_off_max_limit ?? '0',
      round_off_precision: finance_settings?.round_off_precision ?? '0.00',
      round_off_algorithm: finance_settings?.round_off_algorithm ?? 'auto',
      show_multi_branch_for_admins: false,
      enable_whatsapp_bot: true,
      enable_fixed_assets: system_settings?.enable_fixed_assets,
      show_multi_branch_for_users: showMultiBranch,
      enable_approval_transaction:
        system_settings?.enable_approval_transaction ?? '0',
      enable_inter_branch_transaction: true,
      branch_id: user?.user?.branch_id,
      purchase_settings: purchase_settings,
      base_currency: user?.user?.branch?.base_currency ?? 'AED',
      system_settings: system_settings ?? '',
      sales_settings: {
        sq: {
          is_item_description_in_sq_required:
            sales_settings?.is_item_description_in_sq_required ?? '0'
        },
        so: {
          is_item_description_in_so_required:
            sales_settings?.is_item_description_in_so_required ?? '0'
        },
        sd: {
          is_item_description_in_sd_required:
            sales_settings?.is_item_description_in_sd_required ?? '0',
          show_price_on_sales_delivery:
            sales_settings?.show_price_on_sales_delivery ?? '0'
        },
        si: {
          is_item_description_in_si_required:
            sales_settings?.is_item_description_in_si_required ?? '0'
        },
        cn: {
          is_item_description_in_cn_required:
            sales_settings?.is_item_description_in_cn_required ?? '0'
        }
      },
      undeposited_cheque_account: finance_settings?.undeposited_cheque_account
        ? {
            value: finance_settings?.undeposited_cheque_account,
            label: finance_settings?.undeposited_cheque_account_name
          }
        : ''
    });
  };

  const init = async () => {
    if (!loadComplete) {
      getCurrentDate();
      getLatestVersion();
      // getAuthenticatedWithWhatsApp();
      globalConfiguration();
      setLoadComplete(true);
    }
  };

  useEffect(() => {
    init();
    const handleChannelData = data => {
      if (data?.message === 'settings.updated') {
        let finance_settings = data?.data?.branch?.finance_settings;
        let sales_settings = data?.data?.branch?.sales_settings;
        let system_settings = data?.data?.branch?.system_settings;
        let purchase_settings = data?.data?.branch?.purchase_settings;
        addIitemToStore('globalConfiguration', {
          tax_label: finance_settings?.tax_label ?? 'TRN',
          round_off_max_limit: finance_settings?.round_off_max_limit ?? '0',
          round_off_precision: finance_settings?.round_off_precision ?? '0.00',
          round_off_algorithm: finance_settings?.round_off_algorithm ?? 'auto',
          show_multi_branch_for_admins: false,
          enable_whatsapp_bot: true,
          show_multi_branch_for_users: showMultiBranch,
          enable_fixed_assets: system_settings?.enable_fixed_assets,
          enable_approval_transaction:
            system_settings.enable_approval_transaction ?? '0',
          enable_inter_branch_transaction: true,
          system_settings: system_settings ?? '',
          purchase_settings: purchase_settings,
          branch_id: data?.data?.branch_id,
          base_currency: data?.data?.branch?.base_currency ?? 'AED',
          sales_settings: {
            sq: {
              is_item_description_in_sq_required:
                sales_settings?.is_item_description_in_sq_required ?? '0'
            },
            so: {
              is_item_description_in_so_required:
                sales_settings?.is_item_description_in_so_required ?? '0'
            },
            sd: {
              is_item_description_in_sd_required:
                sales_settings?.is_item_description_in_sd_required ?? '0',
              show_price_on_sales_delivery:
                sales_settings?.show_price_on_sales_delivery ?? '0'
            },
            si: {
              is_item_description_in_si_required:
                sales_settings?.is_item_description_in_si_required ?? '0'
            },
            cn: {
              is_item_description_in_cn_required:
                sales_settings?.is_item_description_in_cn_required ?? '0'
            }
          },
          undeposited_cheque_account:
            finance_settings?.undeposited_cheque_account
              ? {
                  value: finance_settings?.undeposited_cheque_account,
                  label: finance_settings?.undeposited_cheque_account_name
                }
              : ''
        });
      }
    };
    eventEmitter.on('settingsEmitter', handleChannelData);
    return () => {
      eventEmitter.off('settingsEmitter', handleChannelData);
    };
  }, []);

  return !loadComplete ? (
    <Container
      className="d-flex align-items-center justify content-center"
      style={{ height: '100vh', width: '100vw' }}
    >
      <LoadingScreen />
    </Container>
  ) : (
    children
  );
};

InitStore.propTypes = {
  children: PropTypes.element.isRequired
};

export default InitStore;
