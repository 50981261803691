import { Fragment, React, useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FaWhatsapp } from 'react-icons/fa';

import { AuthWizardContext } from 'context/Context';
import { checkSessionActiveOrNot, generateUniqueId } from './functions';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import MessagePopup from './MessagePopup';

function WhatsAppBotButton({ pdfUrl, pdfName }) {
  const { user } = useContext(AuthWizardContext);
  let userId = user?.id;
  const domain = window.location.host;
  let whatsAppSessionId = generateUniqueId(userId, domain);
  const Translate = useAxisproTranslate();
  const [openForm, setOpenForm] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [qrCode, setQrCode] = useState();
  const [modalClosingNotification, setModalClosingNotification] =
    useState(false);

  const handleOpenForm = () => {
    checkSessionActiveOrNot(
      whatsAppSessionId,
      setAuthenticated,
      setQrCode,
      setModalClosingNotification
    );
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setAuthenticated(false);
    setQrCode();
  };

  return (
    <Fragment>
      <Button
        size="sm"
        variant={'success'}
        title={Translate('Whatsapp Bot')}
        onClick={handleOpenForm}
        style={{ padding: '3px 10px' }}
      >
        <FaWhatsapp size={18} />
      </Button>
      <MessagePopup
        show={openForm}
        onHide={handleCloseForm}
        qrCode={qrCode}
        authenticated={authenticated}
        modalClosingNotification={modalClosingNotification}
        pdfUrl={pdfUrl}
        pdfName={pdfName}
        whatsAppSessionId={whatsAppSessionId}
      />
    </Fragment>
  );
}

WhatsAppBotButton.propTypes = {
  pdfUrl: PropTypes.string,
  pdfName: PropTypes.string
};

export default WhatsAppBotButton;
