import PropTypes from 'prop-types';

const detailsInputList = {
  title: 'Details line item field list',
  data: [
    {
      uniqueKey: 'item_code',
      title: 'Item Code',
      show: true
    },
    {
      uniqueKey: 'item_description',
      title: 'Item Description',
      show: true
    },
    {
      uniqueKey: 'unit',
      title: 'Unit',
      show: true
    },
    {
      uniqueKey: 'tax_group',
      title: 'Tax Group',
      show: true
    },
    {
      uniqueKey: 'unit_tax',
      title: 'Unit Tax',
      show: true
    },
    {
      uniqueKey: 'discount',
      title: 'Discount / Unit',
      show: true
    }
  ]
};

const glEntryInputList = {
  title: 'GL line item field list',
  data: [
    {
      uniqueKey: 'account_code',
      title: 'Account Code',
      show: true
    },
    {
      uniqueKey: 'cost_center',
      title: 'Cost Center',
      show: true
    },
    {
      uniqueKey: 'memo',
      title: 'Memo',
      show: true
    },
    {
      uniqueKey: 'tax_amount',
      title: 'Tax Amount',
      show: true
    }
  ]
};

const common = {
  date: {
    uniqueKey: 'date',
    title: 'Date',
    show: true
  },
  cost_center: {
    uniqueKey: 'cost_center',
    title: 'Cost Center',
    show: true
  },
  warehouse_name: {
    uniqueKey: 'warehouse_name',
    title: 'Warehouse Name',
    show: true
  },
  warehouse: {
    uniqueKey: 'warehouse_name',
    title: 'Delivery Location',
    show: true
  },
  tax_included: {
    uniqueKey: 'tax_included',
    title: 'Tax Included',
    show: true
  },
  attach_files: {
    uniqueKey: 'attach_files',
    title: 'Attach Files',
    show: true
  },
  notes: {
    uniqueKey: 'notes',
    title: 'Notes',
    show: true
  },
  supplier_name: {
    uniqueKey: 'supplier_name',
    title: 'Supplier Name',
    show: true
  },
  supplier_reference: {
    uniqueKey: 'supplier_reference',
    title: 'Supplier Reference',
    show: true
  },
  payment_term: {
    uniqueKey: 'payment_term',
    title: 'Payment Term',
    show: true
  },
  due_date: {
    uniqueKey: 'due_date',
    title: 'Due Date',
    show: true
  },
  expected_delivery_date: {
    uniqueKey: 'expected_delivery_date',
    title: 'Expected Delivery Date',
    show: true
  },
  contact_number: {
    uniqueKey: 'contact_number',
    title: 'Contact Number',
    show: true
  },
  contact_email: {
    uniqueKey: 'contact_email',
    title: 'Contact Email',
    show: true
  },
  terms_and_conditions: {
    uniqueKey: 'terms_and_conditions',
    title: 'Terms & Conditions',
    show: true
  },
  round_off_amount: {
    uniqueKey: 'round_off_amount',
    title: 'Round off amount',
    show: true
  },
  salesman: {
    uniqueKey: 'salesman',
    title: 'Salesman',
    show: true
  },
  project: {
    uniqueKey: 'project',
    title: 'Project',
    show: true
  },
  sales_type: {
    uniqueKey: 'sales_type',
    title: 'Sales Type',
    show: true
  },
  contract: {
    uniqueKey: 'contract',
    title: 'Contract',
    show: true
  },
  customer_lpo_number: {
    uniqueKey: 'customer_lpo_number',
    title: 'Customer LPO Number',
    show: true
  },
  customer_lpo_date: {
    uniqueKey: 'customer_lpo_date',
    title: 'Customer LPO Date',
    show: true
  },
  enquiry_no: {
    uniqueKey: 'enquiry_no',
    title: 'Enquiry No',
    show: true
  },
  ship_via: {
    uniqueKey: 'ship_via',
    title: 'Shipment Through',
    show: true
  },
  delivery_date: {
    uniqueKey: 'delivery_date',
    title: 'Delivery Date',
    show: true
  },
  deliver_to: {
    uniqueKey: 'deliver_to',
    title: 'Delivered To',
    show: true
  },
  delivery_address: {
    uniqueKey: 'delivery_address',
    title: 'Delivery Address',
    show: true
  },
  title: {
    uniqueKey: 'title',
    title: 'Title',
    show: true
  },
  contract_date: {
    uniqueKey: 'contract_date',
    title: 'Contract Date',
    show: true
  },
  contract_start_date: {
    uniqueKey: 'contract_start_date',
    title: 'Contract Start Date',
    show: true
  },
  expiry_notify_before: {
    uniqueKey: 'expiry_notify_before',
    title: 'Expiry Notify Before',
    show: true
  },
  contract_type: {
    uniqueKey: 'contract_type',
    title: 'Contract Type',
    show: true
  },
  contract_end_date: {
    uniqueKey: 'contract_end_date',
    title: 'Contract End Date',
    show: true
  },
  price_list: {
    uniqueKey: 'price_list',
    title: 'Price List',
    show: true
  },
  assets_type: {
    uniqueKey: 'assets_type',
    title: 'Assets Type',
    show: true
  },
  contract_title: {
    uniqueKey: 'contract_title',
    title: 'Contract Title',
    show: true
  },
  asset_item: {
    uniqueKey: 'asset_item',
    title: 'Asset Item',
    show: true
  },
  customer: {
    uniqueKey: 'customer',
    title: 'Customer',
    show: true
  },
  contract_tenure: {
    uniqueKey: 'contract_tenure',
    title: 'Contract Tenure',
    show: true
  },
  contract_from_date: {
    uniqueKey: 'contract_from_date',
    title: 'Contract From Date',
    show: true
  },
  amount: {
    uniqueKey: 'amount',
    title: 'Amount',
    show: true
  },
  contract_to_date: {
    uniqueKey: 'contract_to_date',
    title: 'Contract To Date',
    show: true
  },
  description: {
    uniqueKey: 'description',
    title: 'Description',
    show: true
  },
  enquiry_from: {
    uniqueKey: 'enquiry_from',
    title: 'Enquiry From',
    show: true
  },
  phone: {
    uniqueKey: 'phone',
    title: 'Phone',
    show: true
  },
  email: {
    uniqueKey: 'email',
    title: 'Email',
    show: true
  },
  priority: {
    uniqueKey: 'priority',
    title: 'Priority',
    show: true
  }
};

function removeItemsFromDetailsInputList(uniqueKeyToRemove) {
  const updatedData = detailsInputList.data.filter(
    item => item.uniqueKey !== uniqueKeyToRemove
  );
  return { ...detailsInputList, data: updatedData };
}

const dataObject = {
  PR: {
    title: 'Request',
    sections: [
      {
        title: 'Main field list',
        data: [
          common.date,
          common.cost_center,
          common.warehouse_name,
          common.tax_included,
          common.attach_files,
          common.notes
        ]
      },
      detailsInputList
    ]
  },
  PO: {
    title: 'Order',
    sections: [
      {
        title: 'Main field list',
        data: [
          common.payment_term,
          common.date,
          common.due_date,
          common.cost_center,
          common.warehouse_name,
          common.contact_number,
          common.contact_email,
          common.tax_included,
          common.attach_files,
          common.notes
        ]
      },
      detailsInputList
    ]
  },
  PD: {
    title: 'Delivery',
    sections: [
      {
        title: 'Main field list',
        data: [
          common.payment_term,
          common.date,
          common.cost_center,
          common.warehouse_name,
          common.contact_number,
          common.contact_email,
          common.tax_included,
          common.attach_files,
          common.notes
        ]
      },
      detailsInputList
    ]
  },
  PI: {
    title: 'Invoice',
    sections: [
      {
        title: 'Main field list',
        data: [
          common.supplier_reference,
          common.payment_term,
          common.date,
          common.due_date,
          common.cost_center,
          common.warehouse_name,
          common.contact_number,
          common.contact_email,
          common.tax_included,
          common.round_off_amount,
          common.attach_files,
          common.terms_and_conditions,
          common.notes
        ]
      },
      detailsInputList,
      glEntryInputList
    ]
  },
  DN: {
    title: 'Debit Note',
    sections: [
      {
        title: 'Main field list',
        data: [
          common.payment_term,
          common.cost_center,
          common.warehouse_name,
          common.date,
          common.due_date,
          common.contact_number,
          common.contact_email,
          common.tax_included,
          common.attach_files,
          common.notes
        ]
      },
      detailsInputList
    ]
  },
  SQ: {
    title: 'Sales Quotation',
    sections: [
      {
        title: 'Main field list',
        data: [
          common.date,
          common.payment_term,
          common.salesman,
          common.sales_type,
          common.expected_delivery_date,
          common.contact_email,
          common.cost_center,
          common.enquiry_no,
          common.contact_number,
          common.attach_files,
          common.terms_and_conditions,
          common.notes
        ]
      },
      removeItemsFromDetailsInputList('tax_group')
    ]
  },
  SO: {
    title: 'Sales Order',
    sections: [
      {
        title: 'Main field list',
        data: [
          common.date,
          common.payment_term,
          common.salesman,
          common.delivery_date,
          common.due_date,
          common.contact_email,
          common.sales_type,
          common.ship_via,
          common.contact_number,
          common.warehouse,
          common.cost_center,
          common.deliver_to,
          common.delivery_address,
          common.attach_files,
          common.notes
        ]
      },
      removeItemsFromDetailsInputList('tax_group')
    ]
  },
  SD: {
    title: 'Sales Delivery',
    sections: [
      {
        title: 'Main field list',
        data: [
          common.date,
          common.payment_term,
          common.salesman,
          common.warehouse,
          common.due_date,
          common.deliver_to,
          common.sales_type,
          common.delivery_date,
          common.contact_email,
          common.cost_center,
          common.ship_via,
          common.contact_number,
          common.delivery_address,
          common.attach_files,
          common.notes
        ]
      },
      removeItemsFromDetailsInputList('tax_group')
    ]
  },
  SI: {
    title: 'Sales Invoice',
    sections: [
      {
        title: 'Main field list',
        data: [
          common.date,
          common.payment_term,
          common.salesman,
          common.warehouse,
          common.due_date,
          common.project,
          common.sales_type,
          common.cost_center,
          common.contract,
          common.attach_files,
          common.terms_and_conditions,
          common.notes,
          common.contact_email,
          common.contact_number,
          common.customer_lpo_number,
          common.customer_lpo_date,
          common.round_off_amount
        ]
      },
      removeItemsFromDetailsInputList('tax_group')
    ]
  },
  CN: {
    title: 'Credit Note',
    sections: [
      {
        title: 'Main field list',
        data: [
          common.date,
          common.payment_term,
          common.contact_email,
          common.sales_type,
          common.due_date,
          common.contact_number,
          common.cost_center,
          common.warehouse,
          common.attach_files,
          common.notes
        ]
      },
      removeItemsFromDetailsInputList('tax_group')
    ]
  },
  CT: {
    title: 'Contract',
    sections: [
      {
        title: 'Main field list',
        data: [
          common.title,
          common.contract_date,
          common.contract_start_date,
          common.expiry_notify_before,
          common.contract_type,
          common.contract_end_date,
          common.price_list,
          common.description
        ]
      },
      removeItemsFromDetailsInputList('tax_group')
    ]
  },
  RCT: {
    title: 'Rental Contract',
    sections: [
      {
        title: 'Main field list',
        data: [
          common.assets_type,
          common.contract_title,
          common.asset_item,
          common.contract_date,
          common.customer,
          common.contract_tenure,
          common.sales_type,
          common.contract_from_date,
          common.amount,
          common.contract_to_date,
          common.description,
          common.attach_files
        ]
      }
    ]
  },
  FAP: {
    title: 'Fixed Asset Purchase',
    sections: [
      {
        title: 'Main field list',
        data: [
          common.supplier_name,
          common.supplier_reference,
          common.payment_term,
          common.date,
          common.due_date,
          common.cost_center,
          common.warehouse_name,
          common.contact_number,
          common.contact_email,
          common.tax_included,
          common.attach_files,
          common.terms_and_conditions,
          common.notes
        ]
      }
    ]
  },
  SE: {
    title: 'Enquiry',
    sections: [
      {
        title: 'Main field list',
        data: [
          common.customer,
          common.date,
          common.enquiry_from,
          common.phone,
          common.email,
          common.salesman,
          common.priority,
          common.description,
          common.attach_files
        ]
      }
    ]
  }
};

export default function getPurchaseFormSettingsData(type) {
  return dataObject?.[type];
}

getPurchaseFormSettingsData.propTypes = {
  type: PropTypes.oneOf([
    'PR',
    'PO',
    'PI',
    'PD',
    'DN',
    'SQ',
    'SO',
    'SD',
    'SI',
    'CN',
    'CT',
    'RCT',
    'FAP'
  ])
};
