import { React, useContext, useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Nav, Collapse } from 'react-bootstrap';
import PropTypes from 'prop-types';

import AppContext from 'context/Context';
import classNames from 'classnames';
import NavbarVerticalMenuItem from './NavbarVerticalMenuItem';

const CollapseItems = ({ route, handleNavItemClick }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClick = nav => {
    if (open) {
      setOpen(false);
    } else {
      if (nav.to) {
        navigate('/' + nav.to);
        setOpen(true);
      } else {
        setOpen(!open);
      }
    }
  };

  useEffect(() => {
    if (route.validatingName) {
      setOpen(
        pathname.split('/')[1].toLocaleLowerCase() ===
          route.validatingName.toLocaleLowerCase()
          ? true
          : false
      );
    } else if (route.subName1) {
      let path = pathname;

      if (pathname && pathname.includes('/')) {
        let parts = pathname.split('/');
        path = parts.slice(0, 3).join('/');
      }

      let subName1 = route.subName1;

      if (route.subName1 && route.subName1.includes('/')) {
        let routeParts = route.subName1.split('/');
        subName1 = routeParts.slice(0, 2).join('/');
      }

      let subName2 = route.subName2;

      if (route.subName2 && route.subName2.includes('/')) {
        let routeParts = route.subName2.split('/');
        subName2 = routeParts.slice(0, 2).join('/');
      }

      let subName3 = route.subName3;

      if (route.subName3 && route.subName3.includes('/')) {
        let routeParts = route.subName3.split('/');
        subName3 = routeParts.slice(0, 2).join('/');
      }

      let subName4 = route.subName4;

      if (route.subName4 && route.subName4.includes('/')) {
        let routeParts = route.subName4.split('/');
        subName4 = routeParts.slice(0, 2).join('/');
      }

      let subName5 = route.subName5;

      if (route.subName5 && route.subName5.includes('/')) {
        let routeParts = route.subName5.split('/');
        subName5 = routeParts.slice(0, 2).join('/');
      }

      let subName6 = route.subName6;

      if (route.subName6 && route.subName6.includes('/')) {
        let routeParts = route.subName6.split('/');
        subName6 = routeParts.slice(0, 2).join('/');
      }

      let subName7 = route.subName7;

      if (route.subName7 && route.subName7.includes('/')) {
        let routeParts = route.subName7.split('/');
        subName7 = routeParts.slice(0, 2).join('/');
      }

      let subName8 = route.subName8;

      if (route.subName8 && route.subName8.includes('/')) {
        let routeParts = route.subName8.split('/');
        subName8 = routeParts.slice(0, 2).join('/');
      }

      let subName9 = route.subName9;

      if (route.subName9 && route.subName9.includes('/')) {
        let routeParts = route.subName9.split('/');
        subName9 = routeParts.slice(0, 2).join('/');
      }

      let subName10 = route.subName10;

      if (route.subName10 && route.subName10.includes('/')) {
        let routeParts = route.subName10.split('/');
        subName10 = routeParts.slice(0, 2).join('/');
      }

      let subName11 = route.subName11;

      if (route.subName11 && route.subName11.includes('/')) {
        let routeParts = route.subName11.split('/');
        subName11 = routeParts.slice(0, 2).join('/');
      }

      let subName12 = route.subName12;

      if (route.subName12 && route.subName12.includes('/')) {
        let routeParts = route.subName12.split('/');
        subName12 = routeParts.slice(0, 2).join('/');
      }

      let subName14 = route.subName14;

      if (route.subName14 && route.subName14.includes('/')) {
        let routeParts = route.subName14.split('/');
        subName14 = routeParts.slice(0, 2).join('/');
      }

      setOpen(
        path == '/' + route.to ||
          path == '/' + subName1 ||
          path == '/' + subName2 ||
          path == '/' + subName3 ||
          path == '/' + subName4 ||
          path == '/' + subName5 ||
          path == '/' + subName6 ||
          path == '/' + subName7 ||
          path == '/' + subName8 ||
          path == '/' + subName9 ||
          path == '/' + subName10 ||
          path == '/' + subName11 ||
          path == '/' + subName12 ||
          path == '/' + subName14
          ? true
          : false
      );
    } else {
      setOpen(
        pathname.split('/')[1].toLocaleLowerCase() ===
          route.to.toLocaleLowerCase()
          ? true
          : false
      );
    }
  }, [pathname]);

  // onClick={e => {
  //         if (e.clientX >= 168 && e.clientX < 182) {
  //           setOpen(true);
  //         } else {
  //           handleClick(route);
  //         }
  //       }}

  return (
    <Nav.Item as="li">
      <Nav.Link
        onClick={() => handleClick(route)}
        className={classNames(
          `dropdown-indicator cursor-pointer pb-0 pe-0 ${route?.tag ?? ''}`,
          {
            'text-500': !route.active
          }
        )}
        aria-expanded={open}
      >
        <NavbarVerticalMenuItem
          route={route}
          open={open}
          handleNavItemClick={handleNavItemClick}
        />
      </Nav.Link>
      <Collapse in={open}>
        <Nav
          className={
            route.children && open
              ? 'flex-column nav active-style-with-children'
              : 'flex-column nav'
          }
          as="ul"
        >
          <NavbarVerticalMenu
            routes={route.children}
            handleNavItemClick={handleNavItemClick}
          />
        </Nav>
      </Collapse>
    </Nav.Item>
  );
};

CollapseItems.propTypes = {
  handleNavItemClick: PropTypes.func,
  route: PropTypes.shape({
    name: PropTypes.string.isRequired,
    to: PropTypes.any,
    icon: PropTypes.string,
    children: PropTypes.array.isRequired,
    active: PropTypes.bool,
    validatingName: PropTypes.string,
    subName1: PropTypes.string,
    subName2: PropTypes.string,
    subName3: PropTypes.string,
    subName4: PropTypes.string,
    subName5: PropTypes.string,
    subName6: PropTypes.string,
    subName7: PropTypes.string,
    subName8: PropTypes.string,
    subName9: PropTypes.string,
    subName10: PropTypes.string,
    subName11: PropTypes.string,
    subName12: PropTypes.string,
    subName14: PropTypes.string,
    tag: PropTypes.string
  })
};

const NavbarVerticalMenu = ({ routes }) => {
  const { pathname } = useLocation();
  const {
    config: { showBurgerMenu, isNavbarVerticalCollapsed },
    setConfig
  } = useContext(AppContext);

  const handleNavItemClick = () => {
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu);
    }
    if (isNavbarVerticalCollapsed) {
      setConfig('isNavbarVerticalCollapsed', false);
    }
  };

  return (
    routes.length > 0 &&
    routes.map((route, index) => {
      if (!route.children) {
        return (
          <div key={index} className="m-0">
            <Nav.Item
              as="li"
              key={route.name}
              onClick={handleNavItemClick}
              className="m-0"
            >
              <NavLink
                end={route.exact}
                to={route.to}
                state={{ open: route.to === '/authentication-modal' }}
                className={({ isActive }) => {
                  let className =
                    pathname == '/sales/customer/add' ||
                    pathname == '/purchase/supplier/add'
                      ? '/' + route.to == pathname
                        ? 'active active-style nav-link'
                        : 'nav-link'
                      : isActive
                      ? 'active active-style nav-link'
                      : 'nav-link';
                  return className + ` ${route?.tag ?? ''}`;
                }}
              >
                <NavbarVerticalMenuItem
                  route={route}
                  key={route.name}
                  handleNavItemClick={handleNavItemClick}
                />
              </NavLink>
            </Nav.Item>
          </div>
        );
      }
      return (
        <div key={index}>
          <CollapseItems
            route={route}
            key={route.name}
            handleNavItemClick={handleNavItemClick}
          />
        </div>
      );
    })
  );
};

NavbarVerticalMenu.propTypes = {
  routes: PropTypes.any
};

export default NavbarVerticalMenu;
